/* 
Responsive styling for market actions and PMT market actions toolbars
There are two containers: dropdowns-container and buttons-container
As the screen size shrinks
    - the buttons-container stays right-aligned and the buttons retain their size.
    - the dropdowns-container and the dropdowns within it shrink
Styling for the dropdown itself is in the class .toolbar-standard-dropdown in the layout file.
*/


.markets-toolbar-responsive {
    display: flex;
    align-items: flex-end; 
    justify-content: space-between;
    gap: 5px;
    flex-wrap: nowrap; // Prevent wrapping at all times
    margin-block-end: $spacing05;

    // dropdowns container should decrease in width as the screen size decreases
    .dropdowns-container {
        display: flex;
        flex-grow: 1; // Take up available space
        flex-shrink: 1; // Allow shrinking if space is tight
        flex-wrap: nowrap; // Keep all dropdowns in a single line
        gap: 5px;
        min-width: 0; // Allow dropdowns container to shrink as much as needed

        .toolbar-dropdown { 
            display: flex;
            flex-grow: 1; // Allow each dropdown to grow and take available space within the container
            flex-shrink: 1; // Allow shrinking if the space is reduced
            min-width: 30px; 
            flex-direction: column;

            label {
                flex-shrink: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
            }
        }
    }

    // buttons should stay the same size and width and not wrap
    .buttons-container {
        display: flex;
        justify-content: flex-end; // align to right
        flex-shrink: 0; // Prevent buttons from shrinking
        gap: 5px;
        width: 280px; // Fixed width to ensure consistent button size
        height: 29.32px; // match dropdowns exactly
        margin-left: auto; // Align buttons to the far right
        flex-wrap: nowrap; // Ensure buttons are always on one line
    }

}


  
// there is a hidden div around the popover
#popover-filter-popover {
    display: flex !important;  
    width: 100% !important;    
    max-width: none;          
    & > div {
        display: block !important;
        width: 100% !important;
        border: none !important;
    }
 }  

 /* Styling for popover container */
#popover-filter-popover {
    .popover-container {
        max-width: 100%;
        width: 100%;
        padding: $spacing04; 
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid #ADB5BD;
        border-radius: 4px;
    }

    .filter-title {
        margin-bottom: 16px; 
        color: $text-primary;
        font-size: 1rem;
        font-weight: bold;
    }

    .add-filter-wrapper {
        padding-top: 16px; 
    }

    .add-filter-btn {
        border-radius: 4px;
        padding: 8px 12px;
        font-size: 0.875rem; 
        color: $text-primary;
        font-weight: 600;
        background-color: $surface-light-secondary;
        &:hover {
            background-color: darken($surface-light-secondary, 5%);
        }
    }
}


// remove arrow
    #popover-filter-popover > div:nth-child(2) > div:nth-child(1) {
    display: none !important;
    }
