@import '../utilities/variable.scss';
@import '../utilities/variable_classes';
@import '../utilities/mixin.scss';

.cx-app {
  //Adds margin-top to form labels
  form {
    min-width: 75vw;
  }

  label.subtitle-lg {
   svg {
     margin-inline-start: $spacing02;
   }
  }
  //Removes the margin from the first two elements for styling consistency
  .label-p0 {
    margin-block-start: 0 !important;
  }

  //Styles Eyes Checkboxes
  .subtitle-sm {
    margin-bottom: 0;
  }
  .eyes-checkbox {
    display: flex;
    margin-block-start: $spacing04;
    .checkbox {
      border: 0;
      color: $text-primary;
      margin-inline-end: $spacing03;
    }
  }
  .tags {
    margin-block-end: $spacing06;
  }
  .report-submit {
    margin-block-start: $spacing05;
  }

  .archive-btn {
    margin-block-end: $spacing04 !important;
  }

  .info-owner {
    margin-block: $spacing05;
    color: $blackwhite500;
    @include h4();
  }
  
  .btn-submit {
     margin-block-end: 36px;
  }
  
  .btn-cancel {
    margin-block-end: 34px;
  }

  .article-col {
    .page-title {
      padding-block-start: 0;
      margin-block-start: $spacing08;
    }
  }

  .article-display {
    .col-md-3 {
      margin-block-start: $spacing08;
    }
  }

  @media (max-width: 1300px) {
    form {
      min-width: 72vw;
    }
  }

  @media (max-width: 1200px) {
    form {
      min-width: 58vw;
    }
  }
}