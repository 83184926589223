@import '../utilities/variable.scss';
@import '../utilities/variable_classes';
@import '../utilities/mixin.scss';

//COMPANY PROFILES LANDING PAGE STYLES -----------------------------------------

.full-co-prof-app {
  .uni-filter-bar {
    margin-block-start: $spacing02;
  }
}

a.website-url {
  @include bodySmall();
  padding: 8px 0;
}

.company-display-container {
  min-height: 60vh;

  .row {


    .related-news-card.weird-corners.card {
      margin-top: 30px;
      margin-left: 12px;
    }

    .info-bubbles {
      margin-inline: 0;
      padding-inline: $spacing04;
      padding-block: $spacing03;
      .logo-col {
        padding: 0;
        max-width: 24px;
      }
      .label {
        @include subtitle2();
      }
      .col-9 {
        @include bodyMedium();
      }
    }
  
    .gx-1 {
      padding-right: 0;
    }
  
    .co-name-link {
      margin-left: 12px;
      h1 {
        width: auto;
        display: inline-block !important;
        margin-bottom: 32px;
        padding-right: 18.5px;
      }
      .website-url {
        width: auto;
        display: inline-block !important;
        padding-top: 16px;
  
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  
    .leadership-container {
      padding-left: 0;
      padding-right: 0;
      .row {
        padding-left: 0;
        .gx-2 {
          padding: 24px 20px 0 0;
        }
      }
    }
    
    h1 {
      @include h1();
      padding: 8px 0;
      margin-bottom: 0;
    }
  
    article {
      @include bodyMedium();
      margin-bottom: 32px;
      a {
        @include bodyMedium();
      }
    }
  
    a {
        @include subtitle1();
    }
  
    .field-header {
      @include h2();
      padding: 8px 0;
      margin-bottom: 0;
    }
  
    .company-field-display {
      
      margin-inline-start: 0;
      h3 {
        @include h4();
        margin-bottom: 0;
        padding: 8px 0 0 0;
      }
  
      .leader-profile-card {
        border: none;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
      }
  
      article {
        p {
          @include bodyMedium();
          padding: 8px 0;
          margin-bottom: 0;
          strong{
            font {
              @include h3();
              color: rgb(33, 37, 41)
            }
          }
        }
        ul {
          li {
            @include bodyMedium();
            padding: 8px 0;
          }
        }
      }
  
      .company-field-pyramid {
        display: block;
        width: 100%;
        min-height: 200px;
        margin-top: 24px;
      }
    }
  }
 
  .field-display-pbi  {
    @include pbiSmall();
  }
}

.leader-profile-card {
  width: auto;
  height: 383px;
  margin-bottom: 8px;

  .card-body {
    max-height: 90px;
    min-width: auto;
    padding: 0 20px 20px 20px;
    .card-title {
      @include h4();
      padding: 8px 0;
      margin-bottom: 0;
    }
    .card-subtitle {
      @include bodyMedium();
      padding: 8px 0;
      font-weight: 400;
      color: #000000;
    }
  }
  
  .card-img-top {
    height: 220px !important;
    width: 220px !important;
    margin: 20px 33px 12px 33px;
    border-radius: 50%;
    align-self: center;
  }
  
}

.flex-column.nav a.active {
  font-weight: bold;
}

//COMPANY PROFILES EXECUTIVE MODAL POPOUT ------------------------------------

.profile-popup {

  .modal-content {
    width: 747px;

    .modal-header {
      display:flex;
      align-items: flex-start;
      padding: 0 16px 0 24px;
      
      .big-photo {
        width: 240PX;
        height: 240px;
        border-radius: 50%;
        margin-top: 20px;
      }
    }
    .modal-body {
      padding: 24px 40px 20px 24px;

      h5{ 
        @include h4();
        padding: 8px 0;
        margin-bottom: 0;
      }

      h6 {
        @include bodyLarge();
        padding: 8px 0;
        margin-bottom: 0;
      }

      .leadership-profile-bio {
        @include bodyLarge();
        padding: 8px 0;
      }
    }
  }
}

//MEDIA QUERIES -----------------------------------------------------

 //XL
 @media (max-width: 1400px) {
  .company-display-container {
    .mini-weird-corners {
      width: auto;
    }

    h1 {
      padding-left: 0;
    }
    .smaller-weird-corners {
      width: auto;
      height: 395px;

      .card-img-top {
        margin: 20px 10.5px 12px 10.5px;
      }
    }
  }
}

//LG
@media (max-width: 1200px) {
  .company-display-container {
    .mini-weird-corners {
      width: auto;
    }
    .smaller-weird-corners {
      min-width: auto;
      .card-img-top {
        margin: 20px 48px 12px 48px;
      }
    }
  }
}

//MED
@media (max-width: 992px) {
  .company-display-container {

    .company-field-display {
      h3 {
        padding-top: 0;
      }
    }
    .smaller-weird-corners {
      width: auto;
      height: 400px;
      .card-img-top {
        margin: 20px 108px 12px 108px;
      }
      .card-body {
        padding-top: 24px;
      }
    }
  }
}

//SM
@media (max-width: 768px) {
  .full-co-prof-app {
    .logo-col {
      padding-bottom: 21px;
      min-width: 100%;
    }
    .link-col {
      text-align: center !important;
      min-width: 100%;
    }
    .title-body-col {
      padding: 0 12px;
      text-align: center;
      h3 {
        padding-bottom: 12px;
      }
      p {
        text-align: left;
        padding-bottom: 20px !important;
      }
    }
  }
}

//XS
@media (max-width: 575px) {

}