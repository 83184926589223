.jvectormap-container {
    height:100%;
    width:100%;
}

.irc_app {

    .dropdown-menu {
        max-height: 400px;
        overflow-y: scroll;
    }

}