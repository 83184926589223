@import '../utilities/variable.scss';
@import '../utilities/variable_classes';
@import '../utilities/mixin.scss';

.toggle {
    cursor: pointer;

    .toggle-switch {
        .toggle-icon {
            margin-inline-end: $spacing02;
        }

        &.disabled {
            color: gray !important;
        }
    }
}
