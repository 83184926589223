.chat-wrapper {
  display: flex;
  flex-direction: column;
  height: 75vh;

  .chat-container {
    flex-grow: 1; 
    display: flex;
    flex-direction: column;
    
    
    .intro-section {
      flex-grow: 1; 
      display: flex;
      flex-direction: column;
      justify-content: center; 
    }
    
    .header {
      @include h1;
    }
    
    .intro {
      @include bodyLarge;
      padding-block-end: $spacing10;
    }


    /* Chat history */
    .chat-history {
      @include bodyLarge;
      flex-grow: 1;
      overflow-y: auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
    }

    /* General chat message styles */
    .chat-message {
      @include bodyLarge;
      padding: $spacing03 $spacing04; 
      border-radius: 16px;
      margin-block-end: $spacing03;
      word-wrap: break-word;
    }

    /* User's message */
    .user-message {
      @include bodyLarge;
      border: 1px solid $border-light;
      background-color: $surface-secondary;
      align-self: flex-end;
      max-width: 75%;
    }

    /* AI's message */
    .assistant-message {
      @include bodyLarge;
      padding: $spacing04, $spacing06;
      background-color: $purewhite;
      align-self: flex-start;
      width: 75%;
      max-width: 750px;
    }

    /* Message input and "Can make mistakes" line */
    .message-container {
      position: sticky;
      bottom: 0;
      padding: 10px 40px;
      background-color: $purewhite;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      z-index: 1000;

      .input-wrapper {
        position: relative;
        width: 100%;

        .message-bar {
          @include bodyLarge;
          width: 100%;
          padding: 12px 50px 12px 20px;
          border-radius: 25px;
          border: 1px solid $border-light;
          outline: none;

          &::placeholder {
            @include bodyLarge;
          }
        }

        .btn-send {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          background-color: $surface-light-secondary;
          border: none;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background-color: $blackwhite100;
          }

          svg { /* ArrowUp */
            width: 16px;
            height: 16px;
          }
        }
      }

      /* "Can make mistakes" message under the message bar */
      .mistake-message {
        @include labelMedium;
        color: $text-primary;
        padding-block-start: 10px;
        padding-block-end: 30px;
        text-align: center;
      }
    }
  }
}
