
@import '../../fonts/OpenSans-Italic-VariableFont_wdth\,wght.ttf';
@import '../../fonts/OpenSans-VariableFont_wdth\,wght.ttf';



// ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS ------ COLORS //

// ----- Brand Colors ----- Brand Colors ----- Brand Colors ----- Brand Colors ----- Brand Colors ----- Brand Colors ----- Brand Colors 

//----- LINKSBRIDGE ---------------------------------------------------------------------

// Linksbridge Primary - Shades of Orange

$linksbridge-brand-primary-50: #F2F4EA;
$linksbridge-brand-primary-100: #FADEBC;
$linksbridge-brand-primary-200: #F8CE9C;
$linksbridge-brand-primary-300: #F5B76F;
$linksbridge-brand-primary-400: #F3A953;
$linksbridge-brand-primary-500: #F09428;
$linksbridge-brand-primary-600: #DA8724;
$linksbridge-brand-primary-700: #AA691C;
$linksbridge-brand-primary-800: #845116;
$linksbridge-brand-primary-900: #653E11;

// Linksbridge Secondary - Shades of Gray

$linksbridge-surface-brand-primary-50: #F9FAFC;

$linksbridge-brand-secondary-100: #EBF0F5;
$linksbridge-brand-secondary-200: #E2E9F0;
$linksbridge-brand-secondary-300: #D5DFE9;
$linksbridge-brand-secondary-400: #CDD9E5;
$linksbridge-brand-secondary-500: #C0CFDE; // Linksbridge Gray - Primary Surface Color
$linksbridge-brand-secondary-600: #AFBCCA;
$linksbridge-brand-secondary-700: #88939D;
$linksbridge-brand-secondary-800: #6A727A;
$linksbridge-brand-secondary-900: #51575D;

// ----- Primatives ----- Primatives ----- Primatives ----- Primatives
$pure-white: #FFFFFF;
$bw-50: #EFEFF1;
$bw-100: #CDCED3;
$bw-200: #B5B6BE;
$bw-300: #9395A0;
$bw-400: #7E818D;
$bw-500: #5E6171;
$bw-600: #565867;
$bw-700: #434550;
$bw-800: #34353E;
$bw-900: #27292F;

// ----- Green ----- Green ----- Green ----- Green ----- Green
$green-50: #E8F3EE;
$green-100: #B8DACA;
$green-200: #95C8B0;
$green-300: #65AF8C;
$green-400: #479F76;
$green-500: #198754;
$green-600: #177B4C;
$green-700: #12603C;
$green-800: #0E4A2E;
$green-900: #0B3923;

// ----- Yellow ----- Yellow ----- Yellow ----- Yellow ----- Yellow

$yellow-50: #FFF9E6;
$yellow-100: #FFECB2;
$yellow-200: #FFE28D;
$yellow-300: #FFD559;
$yellow-400: #FFCD39;
$yellow-500: #FFC107;
$yellow-600: #E8B006;
$yellow-700: #B58905;
$yellow-800: #8C6A04;
$yellow-900: #6B5103;

// ----- Red ----- Red ----- Red ----- Red ----- Red ----- Red

$red-50: #FCEBEC;
$red-100: #F4C0C5;
$red-200: #EFA2A9;
$red-300: #E87882;
$red-400: #E35D6A;
$red-500: #DC3545;
$red-600: #C8303F;
$red-700: #9C2631;
$red-800: #791D26;
$red-900: #5C161D;

//----- Blue ----- Blue ----- Blue ----- Blue ----- Blue

$blue-50: #E7F1FF;
$blue-100: #B4D2FE;
$blue-200: #90BCFE;
$blue-300: #5D9EFE;
$blue-400: #3D8BFD;
$blue-500: #0D6EFD;
$blue-600: #0C64E6;
$blue-700: #094EB4;
$blue-800: #073D8B;
$blue-900: #052E6A;

// ----- Accent Colors ----- Accent Colors ----- Accent Colors

$teal: #26A69A;
$light-purple: #BA68C8;
$peach: #FFAB91;
$mint-green: #B2DFDB;
$lavender: #B39DDB;
$soft-orange: #FFCCBC;
$dusty-rose: #E6BEBE;
$sage-green: #C8E6C9;
$sky-blue: #80DEEA;
$cream: #FFF9C4;
$soft-lilac: #E1BEE7;
$turquoise: #4DD0E1;
$melon: #F48FB1;



// ----- Feedback Colors ----- Feedback Colors ----- Feedback Colors ----- Feedback Colors ----- Feedback Colors ----- Feedback Colors ----- Feedback Colors 
// ----- Information Colors ----- Information Colors ----- Information Colors

$information: $blue-500;
$text-information-onPrimary-light: $blue-600;
$text-information-onSecondary-light: $blue-400;
$text-information-onPrimary-dark: $blue-300;
$text-information-onSecondary-dark: $blue-200;

// ----- Danger Colors ----- Danger Colors ----- Danger Colors

$danger: $red-500;
$text-danger-onPrimary-light: $red-400;
$text-danger-onSecondary-light: $red-600;
$text-danger-onPrimary-dark: $red-300;
$text-danger-onSecondary-dark: $red-200;

// ----- Warning Colors ----- Warning Colors ----- Warning Colors

$warning: $yellow-500;
$text-warning-onPrimary-light: $yellow-600;
$text-warning-onSecondary-light: $yellow-400;
$text-warning-onPrimary-dark: $yellow-300;
$text-warning-onSecondary-dark: $yellow-200;

// ----- Success ----- Success ----- Success ----- Success ----- Success ----- Success ----- Success  
$success: $green-500;
$text-success-onPrimary-light: $green-600;
$text-success-onSecondary-light: $green-400;
$text-success-onPrimary-dark: $green-300;
$text-success-onSecondary-dark: $green-200;

// ----- Text ----- Text ----- Text ----- 
$text-primary-light: $bw-900;
$text-secondary-light: $bw-600;
$text-primary-dark: $bw-50;
$text-secondary-dark: $bw-300;

// ----- Surfaces ----- Surfaces ----- Surfaces -----
$surface-primary-light: $pure-white;
$surface-secondary-light: $bw-50;
$surface-tertiary-light: $surface-primary-light;
$surface-quaternary-light: $surface-secondary-light;
$surface-primary-dark: $bw-900;
$surface-secondary-dark: $bw-800;
$surface-tertiary-dark: $bw-700;
$surface-quaternary-dark: $bw-600;

// ----- Borders ----- Borders ----- Borders -----
$border-primary-light: $bw-100;
$border-secondary-light: $bw-50;
$border-tertiary-light: $surface-primary-light;
$border-quaternary-light: $surface-secondary-light;
$border-primary-dark: $bw-900;
$border-secondary-dark: $bw-800;
$border-tertiary-dark: $bw-700;
$border-quaternary-dark: $bw-600;

// ----- Border Radius ----- Border Radius ----- Border Radius
$border-radius-small: $spacing01;
$border-radius-medium: $spacing02;
$border-radius-large: $spacing08;

// ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY ----- TYPOGRAPHY //

// ----- Font Family ----- Font Family ----- Font Family
$font-family: 'Open Sans', sans-serif;


// ----- Font Weight ----- Font Weight ----- Font Weight
$font-weight-regular: 400;
$font-weight-semi-bold: 500;
$font-weight-bold: 600;

// ----- Font Size ----- Font Size ----- Font Size ----- Font Size ----- Font Size ----- Font Size

// ----- Default Medium -----
$font-size-default-000: 0.625rem; // 10px
$font-size-default-100: 0.75rem; // 12px
$font-size-default-200: 1rem; // 16px
$font-size-default-300: 1.25rem; // 20px
$font-size-default-400: 1.5rem; // 24px
$font-size-default-500: 1.75rem; //28px
$font-size-default-600: 2rem; //32px

// ----- Line Height ----- Line Height ----- Line Height ----- Line Height ----- Line Height -----

// ----- Default Medium -----
$line-height-default-000: 0.75rem; // 12px
$line-height-default-100:  1rem; // 16px
$line-height-default-200: 1.25rem; // 20px
$line-height-default-300: 1.5rem; // 24px
$line-height-default-400: 1.875rem; //30px
$line-height-default-500: 2.25rem; //36px
$line-height-default-600: 2.5rem; //40px


// ----- Spacing ----- Spacing ----- Spacing ----- Spacing ----- Spacing ----- Spacing ----- Spacing ----- Spacing

$spacing01: 0.125rem; //2px
$spacing02: 0.25rem; // 4px
$spacing03: 0.5rem; //8px
$spacing04: 0.75rem; //12px
$spacing05: 1rem; //16px
$spacing06: 1.5rem; //24px 
$spacing07: 2rem; //32px
$spacing08: 2.5rem; //40px
$spacing09: 3rem; //48px
$spacing10: 4rem; //64px
$spacing11: 5rem; //80px
$spacing12: 6rem; //96px
$spacing13: 10rem; //160px
$spacing14: 16rem; //256px


// ----- Box Shadow ----- Box Shadow ----- Box Shadow ----- Box Shadow ----- Box Shadow ----- Box Shadow ----- Box Shadow

$boxshadow-depth-4: 0px 0.5px 1px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
$boxshadow-depth-16: 0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 6px 15px 0px rgba(0, 0, 0, 0.13);
$boxshadow-depth-64: 0px 5px 14px 0px rgba(0, 0, 0, 0.18), 0px 27px 58px 0px rgba(0, 0, 0, 0.22); 


// ----- Mixins ----- Mixins ----- Mixins ----- Mixins ----- Mixins ----- Mixins ----- Mixins ----- Mixins ----- Mixins ----- Mixins ----- Mixins ----- Mixins -----


// ----- Heading ----- Heading ----- Heading ----- Heading ----- Heading ----- Heading
@mixin heading-h1 {
    font-family: $font-family; //open sans
    font-size: $font-size-default-400; //24px
    line-height: $line-height-default-400; //30px
    font-style: normal;
    font-weight: $font-weight-bold; //600
}

@mixin heading-h2 {
    font-family: $font-family; //open sans
    font-size: $font-size-default-300; //20px
    line-height: $line-height-default-300; //24px
    font-style: normal;
    font-weight: $font-weight-bold; //600
}

@mixin heading-h3 {
    font-family: $font-family; //open sans
    font-size: $font-size-default-200; //16px
    line-height: $line-height-default-200; //20px
    font-style: normal;
    font-weight: $font-weight-bold; //600
}


//----- Body ----- Body ----- Body ----- Body ----- Body ----- Body -----  Body ----- Body


// ----- Condensed ----- Condensed ----- Condensed -----
@mixin body-large-condensed {
    font-family: $font-family; //open sans
    font-size: $font-size-default-300; //20px
    line-height: $line-height-default-300; //24px
    font-style: normal;
    font-weight: $font-weight-regular; //400
}

@mixin body-medium-condensed {
    font-family: $font-family; //open sans
    font-size: $font-size-default-200; //16px
    line-height: $line-height-default-200; //20px
    font-style: normal;
    font-weight: $font-weight-regular; //400
}

@mixin body-small-condensed {
    font-family: $font-family; //open sans
    font-size: $font-size-default-100; //12px
    line-height: $line-height-default-100; //16px
    font-style: normal;
    font-weight: $font-weight-regular; //400
}


// ----- comfortable ----- comfortable ----- comfortable -----
@mixin body-large-comfortable {
    font-family: $font-family; //open sans
    font-size: $font-size-default-300; //20px
    line-height: $line-height-default-500; //40px
    font-style: normal;
    font-weight: $font-weight-regular; //400
}

@mixin body-medium-comfortable {
    font-family: $font-family; //open sans
    font-size: $font-size-default-200; //16px
    line-height: $line-height-default-400; //36px
    font-style: normal;
    font-weight: $font-weight-regular; //400
}

@mixin body-small-comfortable {
    font-family: $font-family; //open sans
    font-size: $font-size-default-100; //12px
    line-height: $line-height-default-200; //20px
    font-style: normal;
    font-weight: $font-weight-regular; //400
}


// ----- Label ----- Label ----- Label ----- Label ----- Label ----- Label ----- Label

@mixin label-large {
    font-family: $font-family; //open sans
    font-size: $font-size-default-200 !important; //16px
    line-height: $line-height-default-200; //20px
    font-style: normal;
    font-weight: $font-weight-regular; //400
}

@mixin label-medium {
    font-family: $font-family; //open sans
    font-size: $font-size-default-100 !important; //12px
    line-height: $line-height-default-100; //16px
    font-style: normal;
    font-weight: $font-weight-regular; //400
}

@mixin label-small {
    font-family: $font-family; //open sans
    font-size: $font-size-default-000; //10px
    line-height: $line-height-default-000; //12px
    font-style: normal;
    font-weight: $font-weight-regular; //400
}


// ----- Classes ------ Classes ----- Classes ----- Classes ----- Classes ------ Classes ----- Classes ----- Classes ----- Classes ------ Classes ----- Classes ----- Classes ----- Classes ------ Classes ----- Classes ----- Classes

// ----- Typography ----- Typography ----- Typography ----- Typography ----- Typography ----- Typography
  
// ----- Heading ----- Heading ----- Heading
.heading-h1 {
    @include heading-h1();
}

.heading-h2 {
    @include heading-h2();
}

.heading-h3 {
    @include heading-h3();
}


//----- Body ----- Body ----- Body ----- Body ----- Body ----- Body -----  Body ----- Body


// ----- Condensed ----- Condensed ----- Condensed -----
.body-large-condensed {
    @include body-large-condensed();
}

.body-medium-condensed {
    @include body-medium-condensed();
}

.body-small-condensed {
    @include body-small-condensed()
}


// ----- comfortable ----- comfortable ----- comfortable -----
.body-large-comfortable {
    @include body-large-comfortable();
}

.body-medium-comfortable {
    @include body-medium-comfortable();
}

.body-small-comfortable {
    @include body-small-comfortable();
}


// ----- Label ----- Label ----- Label ----- Label ----- Label ----- Label ----- Label

.label-large {
    @include label-large();
}

.label-medium {
    @include label-medium();
}

.label-small {
    @include label-small();
}



// ----- Font Weight ----- Font Weight ----- Font Weight ----- Font Weight
.font-weight-regular{ 
    font-weight: $font-weight-regular;
}
.font-weight-semi-bold {
    font-weight: $font-weight-semi-bold;
}
.font-weight-bold {
    font-weight: $font-weight-bold;
}

// ----- Colors ----- Colors ----- Colors ----- Colors ----- Colors ----- Colors ----- Colors ----- Colors ----- Colors ----- Colors

// ----- Text ----- Text ----- Text ----- Text
.text-primary-light {
    color: $text-primary-light;
}
.text-secondary-light {
    color: $text-secondary-light;
}
.text-primary-dark {
    color: $text-primary-dark;
}
.text-secondary-dark {
    color: $text-secondary-dark;
}

// ----- Surfaces ----- Surfaces ----- Surfaces -----
.surface-primary-light {
    background-color: $surface-primary-light;
}
.surface-secondary-light {
    background-color: $surface-secondary-light;
}
.surface-tertiary-light {
    background-color: $surface-tertiary-light;
}
.surface-quaternary-light {
    background-color: $surface-quaternary-light;
}
.surface-primary-dark {
    background-color: $surface-primary-dark;
}
.surface-secondary-dark {
    background-color: $surface-secondary-dark;
}
.surface-tertiary-dark {
    background-color: $surface-tertiary-dark;
}
.surface-quaternary-dark {
    background-color: $surface-quaternary-dark;
}

// ----- Accent Color Classes ----- Accent Color Classes ----- Accent Color Classes
.surface-accent-teal {
    background-color: $teal !important;
}
  
.surface-accent-lgt-purple {
    background-color: $light-purple !important;
}
.surface-accent-peach {
    background-color: $peach !important;
}

// ----- Variable Color Classes ----- Variable Color Classes ----- Variable Color Classes

// ----- Backgrounds ----- Backgrounds ----- Backgrounds
.bg-blue-300 {
    background-color: $blue-300 !important;
    border: $blue-300 !important;
}
  
.red-300 {
    background-color: $red-300 !important;
    border: $red-300 !important;
}
  
.green-300 {
    background-color: $green-300 !important;
    border: $green-300 !important;
}
  
.orange-300 {
    background-color: $linksbridge-brand-primary-300 !important;
    border: $linksbridge-brand-primary-300 !important;
}

// ----- Text ----- Text ----- Text
.blackwhite-500 {
    color: $blackwhite500;
}

// ----- Borders ----- Borders ----- Borders -----
.border-primary-light {
    border: solid 1px $border-primary-light;
}
.border-secondary-light {
    border: solid 1px $border-secondary-light;
}
.border-tertiary-light {
    border: solid 1px $border-tertiary-light;
}
.border-quaternary-light {
    border: solid 1px $border-quaternary-light;
}
.border-primary-dark {
    border: solid 1px $border-primary-dark;
}
.border-secondary-dark {
    border: solid 1px $border-secondary-dark;
}
.border-tertiary-dark {
    border: solid 1px $border-tertiary-dark;
}
.border-quaternary-dark {
    border: solid 1px $border-quaternary-dark;
}

// ----- Border None ----- Border None ----- Border None
.border-none {
    border: none;
}

// ----- Border Right ----- Border Right ----- Border Right
.border-right {
    border-right: solid 1px $bw-100;
}

// ----- Border Bottom Light ----- Border Bottom Light
.border-bottom-light {
    border-bottom: solid 1px $bw-100;
}

.border-secondary-light-right {
  border-inline-end: solid 1px $border-secondary-light;
}

.border-secondary-light-left {
  border-inline-start: solid 1px $border-secondary-light;
}
  
  //BORDER RADIUS -----------------------------------BORDER RADIUS-----------------------------------------
  
.border-radius-small {
    border-radius: $border-radius-small !important;
}
  
.border-radius-medium {
    border-radius: $border-radius-medium;
}
.border-radius-large {
    border-radius: $border-radius-large !important;
}



// ----- Box Shadow ----- Box Shadow ----- Box Shadow ----- Box Shadow
.boxshadow-depth-4 {
    box-shadow: $boxshadow-depth-4;
}

.boxshadow-depth-16 {
    box-shadow: $boxshadow-depth-16;
}

.boxshadow-depth-64 {
    box-shadow: $boxshadow-depth-64;
}


// ----- Display Classes ----- Display Classes ----- Display Classes

.display-flex {
    display: flex;
}


.display-block {
    display: block;
}

.display-inline-block {
    display: inline-block;
}


// ----- Overflow Classes ----- Overflow Classes ----- Overflow Classes
.overflow-scroll {
    overflow: scroll !important;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-x-scroll {
    overflow-x: scroll;
}
.overflow-hidden {
    overflow: hidden;
}

.underline-none {
    text-decoration: none;
}


// ----- Text Align ----- Text Align ----- Text Align 
.align-left {
    text-align: left !important;
}
.align-right {
    text-align: right !important;
}
.align-center {
    text-align: center !important;
}


// ----- Vertical Align ----- Vertical Align ----- Vertical Align
.vertical-align {
    vertical-align: middle !important;
}
  
.vertical-align-top {
    vertical-align: top;
}

// ----- Flexbox ----- Flexbox ----- Flexbox ----- Flexbox

.flex-container-50 {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between; /* Space out the children */
    max-width: 50%; /* Ensure the container takes half width */
}
  
.flex-container-100 {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between; /* Space out the children */
    max-width: 100%; /* Ensure the container takes full width */
}
  
  
  // flexbox align content
.align-items-center {
    align-items: center;
}
  
  
  // Justify Content
  
.justify-start {
    justify-content: flex-start;
}
  
.justify-end {
    justify-content: flex-end !important;
}
  
.justify-between {
    justify-content: space-between;
}
  
.justify-around {
    justify-content: space-around;
}
  
.justify-center {
    justify-content: center;
}
  
  // flex-direction
  
.flex-row {
    flex-direction: row;
}
  
.flex-row-reverse {
    flex-direction: row-reverse;
}
  
.flex-col {
    flex-direction: column;
}
  
.flex-col-reverse {
    flex-direction: column-reverse;
}

// ----- Position Styles ----- Position Styles ----- Position Styles

.position-relative {
    position: relative;
}
  
.position-absolute {
    position: absolute;
}

// ----- Sizing Width/Height ----- Sizing Width/Height ----- Sizing Width/Height

// ----- SVG Size ----- SVG Size ----- SVG Size 
.svg-16 {
    width: $spacing05;
    height: $spacing05;
}

.svg-12 {
  width: $spacing04 !important;
  height: $spacing04 !important;
}

// ----- Width 100% ----- Width 100% ----- Width 100%
.full-width {
    min-width: 100% !important;
}

// ----- Width ----- Width ----- Width
.max-width-100 {
    max-width: 100%;
}
.max-width-50 {
    max-width: 50%;
}
  
.max-width-30 {
    max-width: 30%
}
  
.width-35 {
    width: 35%;
}
  
.width-45 {
    width: 45%;
}
  
// ------ Fit Content ----- Fit Content ----- Fit Content 
.width-fit-content {
    width: fit-content;
}
  
.px-width-50 {
    min-width: 50px;
    max-width: 50px;
}
  
.px-width-75 {
    min-width: 75px;
    max-width: 75px;
}
  
.px-width-100 {
    min-width: 100px;
    max-width: 100px;
}
  
.px-width-125 {
    min-width: 125px;
    max-width: 125px;
}
  
.px-width-150 {
    min-width: 150px;
    max-width: 150px;
}
  
.px-width-200 {
    min-width: 200px;
    max-width: 200px;
}
  
.px-width-225 {
    min-width: 225px;
    max-width: 225px;
}
  
.px-width-250 {
    min-width: 250px;
    max-width: 250px;
}
  
.px-width-450 {
    min-width: 450px;
    max-width: 450px;
}
  
.display-block {
    display: block;
}

// ----- Height ----- Height ----- Height
//standard height class 
.max-height-600 {
    max-height: 600px;
  }

// ----- Add Pointer Class ----- Add Pointer Class ----- Add Pointer Class
// Adds a pointer to element when classname is used
.add-pointer {
    &:hover {
      cursor: pointer;
    }
}



// ----- Spacing ----- Spacing ----- Spacing----- Spacing ----- Spacing ----- Spacing----- Spacing ----- Spacing ----- Spacing----- Spacing ----- Spacing ----- Spacing

// ----- Margin Styles ----- Margin Styles ----- Margin Styles ----- Margin Styles ----- Margin Styles ----- Margin Styles ----- Margin Styles ----- Margin Styles
// i = inline - top/bottom
// b = block - left/right
// m = margin

//Margin 0
.m-b-end {
    margin-block-end: 0 !important;
  }
  
  .m-b-start {
    margin-block-start: 0 !important;
  }
  
  .m-i-end {
    margin-inline-end: 0 !important;
  }
  
  .m-i-start {
    margin-inline-start: 0 !important;
  }
  
  //Margin $spacing02
  .m-b-end-02 {
    margin-block-end: $spacing02;
  }
  
  .m-b-start-02 {
    margin-block-start: $spacing02;
  }
  
  .m-i-end-02 {
    margin-inline-end: $spacing02;
  }
  
  .m-i-start-02 {
    margin-inline-start: $spacing02 !important;
  }
  
  //Margin $spacing03
  .m-b-end-03 {
    margin-block-end: $spacing03;
  }
  
  .m-b-start-03 {
    margin-block-start: $spacing03;
  }
  
  .m-i-end-03 {
    margin-inline-end: $spacing03 !important;
  }
  
  .m-i-start-03 {
    margin-inline-start: $spacing03 !important;
  }
  
  //Margin $spacing04
  .m-b-end-04 {
    margin-block-end: $spacing04;
  }
  
  .m-b-start-04 {
    margin-block-start: $spacing04;
  }
  
  .m-i-end-04 {
    margin-inline-end: $spacing04;
  }
  
  .m-i-start-04 {
    margin-inline-start: $spacing04;
  }
  
  //Margin $spacing05
  .m-b-end-05 {
    margin-block-end: $spacing05 !important;
  }
  
  .m-b-start-05 {
    margin-block-start: $spacing05;
  }
  
  .m-i-end-05 {
    margin-inline-end: $spacing05;
  }
  
  .m-i-start-05 {
    margin-inline-start: $spacing05;
  }
  
  //Margin $spacing06
  .m-b-end-06 {
    margin-block-end: $spacing06 !important;
  }
  
  .m-b-start-06 {
    margin-block-start: $spacing06 !important;
  }
  
  .m-i-end-06 {
    margin-inline-end: $spacing06 !important;
  }
  
  .m-i-start-06 {
    margin-inline-start: $spacing06;
  }
  
  //Margin $spacing07
  .m-b-end-07 {
    margin-block-end: $spacing07;
  }
  
  .m-b-start-07 {
    margin-block-start: $spacing07;
  }
  
  .m-i-end-07 {
    margin-inline-end: $spacing07;
  }
  
  .m-i-start-07 {
    margin-inline-start: $spacing07;
  }
  
  //Margin $spacing08 2.5rem
  .m-b-end-08 {
    margin-block-end: $spacing08 !important;
  }
  
  .m-b-start-08 {
    margin-block-start: $spacing08;
  }
  
  .m-i-end-08 {
    margin-inline-end: $spacing08;
  }
  
  .m-i-start-08 {
    margin-inline-start: $spacing08;
  }
  
  //Margin $spacing09
  .m-b-end-09 {
    margin-block-end: $spacing09;
  }
  
  .m-b-start-09 {
    margin-block-start: $spacing09;
  }
  
  .m-i-end-09 {
    margin-inline-end: $spacing09;
  }
  
  .m-i-start-09 {
    margin-inline-start: $spacing09;
  }
  
  //Margin $spacing10 4rem
  .m-b-end-10 {
    margin-block-end: $spacing10;
  }
  
  .m-b-start-10 {
    margin-block-start: $spacing10 !important;
  }
  
  .m-i-end-10 {
    margin-inline-end: $spacing10;
  }
  
  .m-i-start-10 {
    margin-inline-start: $spacing10;
  }
  
  //Margin $spacing11
  .m-b-end-11 {
    margin-block-end: $spacing11;
  }
  
  .m-b-start-11 {
    margin-block-start: $spacing11;
  }
  
  .m-i-end-11 {
    margin-inline-end: $spacing11;
  }
  
  .m-i-start-11 {
    margin-inline-start: $spacing11;
  }
  
  //Margin $spacing12
  .m-b-end-12 {
    margin-block-end: $spacing12;
  }
  
  .m-b-start-12 {
    margin-block-start: $spacing12;
  }
  
  .m-i-end-12 {
    margin-inline-end: $spacing12;
  }
  
  .m-i-start-12 {
    margin-inline-start: $spacing12;
  }
  
  //Margin $spacing13
  .m-b-end-13 {
    margin-block-end: $spacing13;
  }
  
  .m-b-start-13 {
    margin-block-start: $spacing13;
  }
  
  .m-i-end-13 {
    margin-inline-end: $spacing13;
  }
  
  .m-i-start-13 {
    margin-inline-start: $spacing13;
  }
  
  // ----- Padding Styles ----- Padding Styles ----- Padding Styles ----- Padding Styles ----- Padding Styles ----- Padding Styles ----- Padding Styles ----- Padding Styles
  // i = inline - top/bottom
  // b = block - left/right
  // p = padding
  
  //Padding 0
  .p-i {
    padding-inline: 0 !important;
  }
  
  .p-b-end {
    padding-block-end: 0 !important;
  }
  
  .no-padding {
    padding: 0 !important; 
  }
  
  .p-b-start {
    padding-block-start: 0 !important;
  }
  
  .p-i-end {
    padding-inline-end: 0 !important;
  }
  
  .p-i-start {
    padding-inline-start: 0 !important;
  }
  
  .p-02 {
    padding: $spacing02;
  }
  
  .p-i-02 {
    padding-inline: $spacing02;
  }
  
  .p-b-02 {
    padding-block: $spacing02;
  }
  
  .p-b-end-02 {
    padding-block-end: $spacing02;
  }
  
  .p-b-start-02 {
    padding-block-start: $spacing02;
  }
  
  .p-i-end-02 {
    padding-inline-end: $spacing02;
  }
  
  .p-i-start-02 {
    padding-inline-start: $spacing02;
  }
  
  //Padding $spacing03
  .p-03 {
    padding: $spacing03 !important;
  }
  
  .p-i-03 {
    padding-inline: $spacing03;
  }
  
  .p-b-03 {
    padding-block: $spacing03 !important;
  }
  
  .p-b-end-03 {
    padding-block-end: $spacing03;
  }
  
  .p-b-start-03 {
    padding-block-start: $spacing03 !important;
  }
  
  .p-i-end-03 {
    padding-inline-end: $spacing03;
  }
  
  .p-i-start-03 {
    padding-inline-start: $spacing03;
  }
  
  //Padding $spacing04
  .p-04 {
    padding: $spacing04;
  }

  .p-b-04 {
    padding-block: $spacing04 !important;
  }
  
  .p-b-end-04 {
    padding-block-end: $spacing04;
  }
  
  .p-b-start-04 {
    padding-block-start: $spacing04;
  }
  
  .p-i-04 {
    padding-inline: $spacing04;
  }
  
  .p-i-end-04 {
    padding-inline-end: $spacing04;
  }
  
  .p-i-start-04 {
    padding-inline-start: $spacing04 !important;
  }
  
  //Padding $spacing05
  .p-05 {
    padding: $spacing05;
  }
  .p-b-05 {
    padding-block: $spacing05 !important;
  }
  .p-b-end-05 {
    padding-block-end: $spacing05;
  }
  
  .p-b-start-05 {
    padding-block-start: $spacing05 !important;
  }
  
  .p-i-end-05 {
    padding-inline-end: $spacing05;
  }
  
  .p-i-start-05 {
    padding-inline-start: $spacing05;
  }
  
  //Padding $spacing06
  .p-i-06 {
    padding-inline: $spacing06 !important;
  }

  .p-b-end-06 {
    padding-block-end: $spacing06;
  }
  
  .p-b-start-06 {
    padding-block-start: $spacing06 !important;
  }
  
  .p-i-end-06 {
    padding-inline-end: $spacing06;
  }
  
  .p-i-start-06 {
    padding-inline-start: $spacing06 !important;
  }
  
  //Padding $spacing07
  .p-b-end-07 {
    padding-block-end: $spacing07;
  }
  
  .p-b-start-07 {
    padding-block-start: $spacing07;
  }
  
  .p-i-end-07 {
    padding-inline-end: $spacing07;
  }
  
  .p-i-start-07 {
    padding-inline-start: $spacing07;
  }
  
  //Padding $spacing08
  
  .p-08 {
    padding: $spacing08;
  }
  .p-b-end-08 {
    padding-block-end: $spacing08;
  }
  
  .p-b-start-08 {
    padding-block-start: $spacing08;
  }
  
  .p-i-end-08 {
    padding-inline-end: $spacing08;
  }
  
  .p-i-start-08 {
    padding-inline-start: $spacing08;
  }
  
  //Padding $spacing09
  
  .p-b-end-09 {
    padding-block-end: $spacing09;
  }
  
  .p-b-start-09 {
    padding-block-start: $spacing09;
  }
  
  .p-i-end-09 {
    padding-inline-end: $spacing09;
  }
  
  .p-i-start-09 {
    padding-inline-start: $spacing09;
  }
  
  //Padding $spacing10
  .p-b-end-10 {
    padding-block-end: $spacing08;
  }
  
  .p-b-start-10 {
    padding-block-start: $spacing10;
  }
  
  .p-i-end-10 {
    padding-inline-end: $spacing10;
  }
  
  .p-i-start-10 {
    padding-inline-start: $spacing10;
  }
  
  //Padding $spacing11
  .p-b-end-11 {
    padding-block-end: $spacing11;
  }
  
  .p-b-start-11 {
    padding-block-start: $spacing11;
  }
  
  .p-i-end-11 {
    padding-inline-end: $spacing11;
  }
  
  .p-i-start-11 {
    padding-inline-start: $spacing11;
  }
  
  //Padding $spacing12
  .p-b-end-12 {
    padding-block-end: $spacing12;
  }
  
  .p-b-start-12 {
    padding-block-start: $spacing12;
  }
  
  .p-i-end-12 {
    padding-inline-end: $spacing12;
  }
  
  .p-i-start-12 {
    padding-inline-start: $spacing12;
  }
  
  //Padding $spacing13
  .p-b-end-13 {
    padding-block-end: $spacing13;
  }
  
  .p-b-start-13 {
    padding-block-start: $spacing13;
  }
  
  .p-i-end-13 {
    padding-inline-end: $spacing13;
  }
  
  .p-i-start-13 {
    padding-inline-start: $spacing13;
  }