@import '../utilities/variable.scss';
@import '../utilities/variable_classes';
@import '../utilities/mixin.scss';

.uni-filter-bar {
  margin-block-start: $spacing10;

  .filter-bar {
    .filter-box {
      width: 100%;
      padding-inline: 0;
      margin-inline: 0;
      margin-block-end: $spacing04;
      //Filter label titles styles
      .filter-select-title {
        padding-inline-start: 0;
        margin-block-end: $spacing03;
        @include subtitle1()
      }
      .filter-header {
        padding-inline-start: 0;
        @include h6();
        margin-block-start: $spacing04;
      }
      .date-to {
        margin-block-start: $spacing04;
      }
      .date-range {
        width: 100%;
        padding-inline: 0;
        margin-inline-start: 0;
        label {
          padding-inline-start: 0;
          margin-block-end: $spacing03;
          @include subtitle1();
        }
      }
    }
    .filter-btn {
      margin-block-start: $spacing06;
    }

    .agility-filter-btn {
      .clear-filter {
        margin-inline-end: $spacing02;
      }
      .col {
        padding-inline: 0;
        .btn-sm-solid-100 {
          margin-inline-start: $spacing02;
        }
      }
    }
  }

  .news-filter {
    margin-block-start: $spacing07;
  }
}