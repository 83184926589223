@charset 'utf-8';
@import '../utilities/variable';
@import '../utilities/mixin';


// simple, light dropdown (as used on Market Actions toolbar)



.dropdown-label {
  @include bodySmall();
}

    // Dropdown
    .simple-light { 
        display: flex;
        align-items: flex-end;
        gap: 12px;
        flex: 1 0 0;

        
    
        .toggle-selector.dropdown-toggle.btn.btn-light {
            background-color: $purewhite;
            color: #27292F;
            border: none;
            width: 100%;
            text-align: left;
            @include bodySmall();
            margin-top: 8px;
            padding: 12px;
            border: 1px solid #5E6171;
            border-radius: 4px;
          }
    
          .toggle-selector.dropdown-toggle.btn.btn-light::after {
            float: right;
            margin-top: 5px;
          }

        .dropdown-menu {  
          max-height: 400px;
          background: $purewhite;
          border-radius: 0%;
          min-width: 100px;
          width: 100%;
          padding: $spacing04;
        }
        .dropdown-option {
            margin:8px;
            width: 100%;
            background-color: $purewhite;
            @include bodySmall();
          }
          input {
            border: none;
            @include bodySmall();
            line-height: 14px;
          }

          label {
            padding-inline-start: $spacing03;
        }

    }
    