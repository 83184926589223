// VACCINE MARKET STYLES------------------------------------------------------------------------


.market-analysis-display {
  h1 {
   padding: 8px 0;
   padding-left: 0 !important;
   margin-bottom: 12px;
  }

  .company-field-display {
    margin-top: 0;
    div.display-area {
      top: 0 !important;
    }

  .field-display-pbi  {
    @include pbiTall();
     
  }
}
  
  
  h3 {
    @include h3();
    padding: 0;
  }
  
  article {
    @include bodyMedium();
    padding: 0;
    margin-bottom: 48px;
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .field-header {
    @include h2();
  }
  
}

.vaccine-mkt-page {
  h2 {
    @include h2();
    padding: 8px 0;
  }


  .list-container {
    padding-inline-end: $spacing04;
    .app-list-item {
      min-width: 65vw;
      padding-block-end:  $spacing06;
      margin-block-end: $spacing06 !important;
      border: none;
      border-bottom: solid 1px $borders-border-secondary;
      border-radius: 0%;
      .title-body-col {
        padding-inline: 12px;
        h3 {
          @include h4();
          padding: 8px 0 4px 0;
        }
        p {
          display: none;
        }
      }
      
      .link-col {
        .btn {
          @include buttonSmall();
          padding: 10px 20px;
          border-radius: 2px;
        }
      }
    }
  }
}