// @media screen and (-webkit-min-device-pixel-ratio: 1.25) {
//   :root {
//     font-size: .875em !important;
//   }
//   html, body {
//     font-size: .875em !important;
//   }
    
// }

// @media screen and (min-resolution: 1.25dppx) {
//   :root {
//     font-size: .875em !important;
//   }
//   html, body {
//     font-size: .875em !important;
//   }
    
// }

// @media screen and (-webkit-min-device-pixel-ratio: 1.5) {
//   :root {
//     font-size: .7em !important;
//   }
//   html, body {
//     font-size: .7em !important;
//   }
    
// }

@media screen and (min-resolution: 1.5dppx) and (max-resolution: 1.75dppx) {
  :root {
    font-size: .875em !important;
  }
  html, body {
    font-size: .875em !important;
  }
    
}

// @media screen and (-webkit-min-device-pixel-ratio: 1.75) {
//   :root {
//     font-size: .66em !important;
//   }
//   html, body {
//     font-size: .66em !important;
//   }
    
// }

// @media screen and (min-resolution: 1.75dppx) {
//   :root {
//     font-size: .66em !important;
//   }
//   html, body {
//     font-size: .66em !important;
//   }
    
// }

// @media screen and (-webkit-min-device-pixel-ratio: 2) {
//   :root {
//     font-size: .625em !important;
//   }
//   html, body {
//     font-size: .625em !important;
//   }
    
// }

// @media screen and (min-resolution: 2dppx) {
//   :root {
//     font-size: .625em !important;
//   }
//   html, body {
//     font-size: .625em !important;
//   }
    
// }

// @media screen and (-webkit-min-device-pixel-ratio: 3) {
//   :root {
//     font-size: 1em !important;
//   }
//   html, body {
//     font-size: 1em !important;
//   }
    
// }

// @media screen and (min-resolution: 3dppx) {
//   :root {
//     font-size: 1em !important;
//   }
//   html, body {
//     font-size: 1em !important;
//   }
    
// }