@import '../utilities/variable.scss';
@import '../utilities/variable_classes';
@import '../utilities/mixin.scss';

.super_admin_page {
    .gridjs-wrapper {
        overflow-x: scroll;
        width: 1200px;

        .gridjs-tbody {
            height: 800px;
            overflow-y: scroll;
        }
    }

    
}

.wrkspace-chooser {
    padding: $spacing05;
}