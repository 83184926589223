// TENANT APP VARIABLES ------------------------------------------------
$tps-dark: rgba(5, 10, 36, 1);
$gray-text: rgba(5, 10, 36, .5);
$tenant-primary: rgba(0, 0, 0, 0.8);
$tenant-secondary: #D10A09;
$tenant-tertiary: #2d579e;
$cco-primary: rgba(0, 0, 0, 0.8);
$cco-secondary: #D10A09;
$cco-tertiary: #2d579e;
$dcvm-primary:  #9B242D;
$dcvm-secondary: #3086AB;
$dcvm-tertiary: #8CB7C7;
$gavi-primary: #006AB5;
$gavi-secondary: #87B800;
$gavi-tertiary: #2E83BF;
$gh_spm-primary: rgba(0, 0, 0, 0.8);
$gh_spm-secondary: #D10A09;
$gh_spm-tertiary: #2d579e;
$pavm-primary: rgba(0, 0, 0, 0.8);
$pavm-secondary: #D10A09;
$pavm-tertiary: #2d579e;
$hppm-md-portal-primary: rgba(0, 0, 0, 0.8);
$hppm-md-portal-secondary: #D10A09;
$hppm-md-portal-tertiary: #2d579e;
$lb-level-q-primary: rgba(0, 0, 0, 0.8);
$lb-level-q-secondary: #D10A09;
$lb-level-q-tertiary: #2d579e;
$lb-orange-500: #f09428;

$surface-light-tertiary: #FFFFFF; 
$surface-light-secondary: #EFEFF1;

$openSans: 'Open Sans', sans-serif;

//black and white ---------------------
$pureblack: black;
$purewhite: #FFFFFF;

$blackwhite50: #EFEFF1;
$blackwhite100: #CDCED3;
$blackwhite200: #B5B6BE;
$blackwhite300: #9395A0;
$blackwhite400: #7E818D;
$blackwhite500: #5E6171;
$blackwhite600: #565867; // text-secondary
$blackwhite700: #434550;
$blackwhite800: #34353E;
$blackwhite900: #27292F; // this is also text primary
//Greyscale ---------------------------
$greyscale50: #F7F8F8;
$greyscale100: #E6E8EB;
$greyscale200: #D9DDE1;
$greyscale300: #C8CDD3;
$greyscale400: #BDC4CA;
$greyscale500: #ADB5BD;
$greyscale600: #9DA5AC;
$greyscale700: #7B8186;
$greyscale800: #5F6468;
$greyscale900: #494C4F;

// Blue -------------------------------
$blue50: #E7F1FF;
$blue100: #B4D2FE;
$blue200: #90BCFE;
$blue300: #5D9EFE;
$blue400: #3D8BFD;
$blue500: #0D6EFD;
$blue600: #0C64E6;
$blue700: #094EB4;
$blue800: #073D8B;
$blue900: #052E6A;

//Red ---------------------------------------------

$red50: #FCEBEC;
$red100: #F4C0C5;
$red200: #EFA2A9;
$red300: #E87882;
$red400: #E35D6A;
$red500: #DC3545;
$red600: #C8303F;
$red700: #9C2631;
$red800: #791D26;
$red900: #5C161D;

//green----------------------------------------------------------

$green50: #E8F3EE;
$green100: #B8DACA;
$green200: #95C8B0;
$green300: #65AF8C;
$green400: #479F76;
$green500: #198754;
$green600: #177B4C;
$green700: #12603C;
$green800: #0E4A2E;
$green900: #0B3923;

//Accent Colors-----------------------------------------------------
$teal: #26A69A;
$light-purple: #BA68C8;
$peach: #FFAB91;
$mint-green: #B2DFDB;
$lavender: #B39DDB;
$soft-orange: #FFCCBC;
$dusty-rose: #E6BEBE;
$sage-green: #C8E6C9;
$sky-blue: #80DEEA;
$cream: #FFF9C4;
$soft-lilac: #E1BEE7;
$turquoise: #4DD0E1;
$melon: #F48FB1;

//Indigo -----------------------------------------

$indigo500: #6610F2;

//CEPI Color System ----------------------------------------
$cepiPrimaryDark: #001D44;
$cepiPrimaryMedium: #002C60;
$cepiPrimaryLight: #BBC5D2;
$cepiSecondaryDark: #E85852;
$cepiSecondaryMedium: #FF7670;
$cepiSecondaryLight: #FF8F8A;

// Gavi Color System ----------------------------------------
$gaviBlue50:  #E6F0F8;
$gaviBlue100: #B0D1E8;
$gaviBlue200: #8ABADD;
$gaviBlue300: #549BCD;
$gaviBlue400: #3388C4;
$gaviBlue500: #006AB5;
$gaviBlue600: #0060A5;
$gaviBlue700: #004B81;
$gaviBlue800: #003A64;
$gaviBlue900: #002D4C;

//BMGF Color System------------------------------------------

$BMGF300: #BC6C72;
$BMGF500: #9B242D;
$BMGF700: #6E1A20;
$BMGFblack: $pureblack;

//GHMH-------------------------------------------------------

$GHMH300: #547990;
$GHMH500: #003759;
$GHMH700: #00273F;

// CHAI-PRIMARY----------------------------------------------------------
$CHAIprimary50: rgb(230, 236, 242);
$CHAIprimary100: rgb(176, 195, 213);
$CHAIprimary200: rgb(138, 166, 193);
$CHAIprimary300: rgb(84, 126, 165);
$CHAIprimary400: rgb(51, 101, 147);
$CHAIprimary500: rgb(0, 62, 120);
$CHAIprimary600: rgb(0, 56, 109);
$CHAIprimary700: rgb(0, 44, 85);
$CHAIprimary800: rgb(0, 34, 66);
$CHAIprimary900: rgb(0, 26, 50);
//CHAI SECONDARY
$CHAIsecondary50: rgb(231, 242, 245);
$CHAIsecondary100: rgb(181, 213, 222);
$CHAIsecondary200: rgb(146, 193, 207);
$CHAIsecondary300: rgb(96, 165, 185);
$CHAIsecondary400: rgb(65, 148, 171);
$CHAIsecondary500: rgb(17, 121, 150);
$CHAIsecondary600: rgb(15, 110, 137);
$CHAIsecondary700: rgb(12, 86, 107);
$CHAIsecondary800: rgb(9, 67, 83);
$CHAIsecondary900: rgb(7, 51, 63);



// HCIH ----------------------------------------------------------

$HCIH300: #4f6789;
$HCIH500: #23416B;
$HCIH700: #192e4b;
$HCIHblack: $pureblack;


//----- LINKSBRIDGE ---------------------------------------------------------------------

// Linksbridge Primary - Shades of Orange

$LBprimary50: #F2F4EA;
$LBprimary100: #FADEBC;
$LBprimary200: #F8CE9C;
$LBprimary300: #F5B76F;
$LBprimary400: #F3A953;
$LBprimary500: #F09428;
$LBprimary600: #DA8724;
$LBprimary700: #AA691C;
$LBprimary800: #845116;
$LBprimary900: #653E11;

// Linksbridge Secondary - Shades of Gray

$LBsurface50: #F9FAFC;
$LBsecondary100: #EBF0F5;
$LBsecondary200: #E2E9F0;
$LBsecondary300: #D5DFE9;
$LBsecondary400: #CDD9E5;
$LBsecondary500: #C0CFDE; // Linksbridge Gray - Primary Surface Color
$LBsecondary600: #AFBCCA;
$LBsecondary700: #88939D;
$LBsecondary800: #6A727A;
$LBsecondary900: #51575D;



//----- META COLORS ---------------------------------------------------------------------
$METAprimary: #9298cb;
$METAsecondary:#000000;
$METAtertiary: #b6badc;

//SPACING--------------SPACING-------------SPACING-------------SPACING-------------SPACING------------SPACING
$spacing01: 0.125rem; //2px
$spacing02: 0.25rem; //4px
$spacing03: 0.5rem; //8px
$spacing04: 0.75rem; //12px
$spacing05: 1rem; //16px
$spacing06: 1.5rem; //24px
$spacing07: 2rem; //32px
$spacing08: 2.5rem; //40px
$spacing09: 3rem; //48px
$spacing10: 4rem; //64px
$spacing11: 5rem; //80px
$spacing12: 6rem; //96px
$spacing13: 10rem; //160px
$spacing14: 16rem; //256px

//BORDER RADIUS VARIABLES

//BORDERS
$borders-border-secondary: $blackwhite300;
$border-radius-sm: $spacing01;
$border-radius-md: $spacing02;
$border-radius-lg: $spacing08;

$border-light: $blackwhite100;

//SURFACES
$surface-primary: $purewhite;
$surface-secondary: $blackwhite50;
$surface-brand-primary: $LBsecondary500;



//TEXT
$text-primary: $blackwhite900;
$text-secondary: $blackwhite600;

//LINKSBRIDGE 
$surface-brand-linksbridge: $lb-orange-500;
