@import '../utilities/mixin';
@import '../utilities/variable';

//SIDE NAV STYLES ---------------------------------------------------------

.side-nav {
  border: none !important;
  border-radius: 0% !important;


  .card-img-top {
    margin-block-start: $spacing08;
    margin-block-end: $spacing06;
    object-fit: contain;
    max-width: 306px;
    max-height: 153px;
  }
  .structure-container {
    background-color: $purewhite;
    margin: 8px 0 0 0;
    padding: 0;


    .rc-collapse {
      background: none;
      border: none;
    }

    .rc-collapse-content-inactive {
      display: none;
    }

    .rc-collapse-content-active {
      background: none;
      border: none;
     
      svg {
        display: none;
      }


    }

    .rc-collapse-item {
      border: none !important;
      .arrow {
        text-align: center;
        &:before {
          position: relative;
          top: -3px;
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-right: 1px solid #27292F;
          border-top: 1px solid #27292F;
          transform: rotate(45deg);
          margin-right: 0.5em;
          margin-left: 1.0em;
        }
      }
      .rc-collapse-header {
        padding: 8px 0;
        @include bodyMedium();
        border-top: 1px solid #27292F;
        border-bottom: 1px solid #27292F;
        color: #5E6171;

        .rc-collapse-expand-icon {
          display: inline;
        }
      }

      .rc-collapse-header:hover {
        cursor: pointer;
      }
      .sub-link:hover {
        cursor: pointer;
        background-color: #434550;
        color: #FFFFFF;
      }
    }

    .rc-collapse-item-active {
      .rc-collapse-header {
        color: #27292F !important;
        font-weight: 500;
        border-left: 4px solid #27292F;
      }
      .arrow {
        text-align: center;
        &:before {
          position: relative;
          top: -3px;
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-right: 1px solid #27292F;
          border-top: 1px solid #27292F;
          transform: rotate(135deg);
          margin-right: 0.5em;
          margin-left: 1.0em;
        }
      }
    }

    .sub-link {
      display: block;
      color: #5E6171;
      @include bodyMedium();
      padding: 8px 0 8px 52px;
      border-bottom: 1px solid #27292F;

      &:last-child {
        border-bottom: none;
      }
    }

    .sub-link.active {
      color: #27292F;
      border-left: 2px solid #27292F;
      background-color: #CDCED3;
    }
  }
}

.company-side-nav {
  margin-bottom: 25px;
  margin-left: 12px;
}