@charset 'utf-8';

@use './layout/base';
@use './layout/layout';
@use './components/footer';
@use './components/navBar';
@use './components/pagination';
@use './components/supportModal';
@use './components/dropdowns';
@use './components/listView';
@use './components/buttons';
@use './components/tables';
@use './components/confirmationModal';
// @import './components/alchemy';
// @use './components/coProApp';
// @use './components/sideNav.scss';
@use './utilities/customTheme';
// @use './utilities/mixin';
// @use './utilities/variable';








