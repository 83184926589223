@charset 'utf-8';
@import '../utilities/variable';
@import '../utilities/mixin';

.toast-alert {
  .modal-dialog {
    max-width: 452px;
  }
  .modal-title {
    @include h4();
  }
  .modal-body {
    @include bodySmall();
    padding-left: 16px;
    .body-1 {
      margin-bottom: 16px;
    }
    a {
      padding-left: 4px;
    }
  }
  .modal-footer {
    .btn {
      border-radius: 2px;
      margin: 0;
      width: 204px;
    }
    .no-btn {
      background-color: $blackwhite50;
      color: $blackwhite500;
      border: 1px solid $blackwhite500;
      margin-right: 12px;
    }
  }
}