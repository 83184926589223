@import '../utilities/variable';

.jvectormap-label {
    position: absolute;
    display: none;
    border: solid 1px $blackwhite100;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: $blackwhite900;
    color: $blackwhite100;
    font-size: smaller;
    padding: 3px;
    z-index:11;
 }

 .jvectormap-tip {
    position: absolute;
    display: none;
    border: solid 1px $blackwhite100;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: $blackwhite900;
    color: $blackwhite100;
    font-size: smaller;
    padding: 3px;
    z-index:11;
 }