@import '../utilities/variable.scss';
@import '../utilities/variable_classes';
@import '../utilities/mixin.scss';

.article-display {
  h2 {
    @include h2();
    padding-block-start: $spacing03;
    margin-block-end: $spacing08;
    color: $text-primary;
  }

  .subtitle {
    @include bodyLarge();
    color: $text-primary;
  }
  .article-col {
    a {
      @include bodyLarge();
      text-decoration: none !important;
      color: $text-primary; 
    }

    a.file-link {
      text-decoration: underline !important;
      color: $blue700;
      display: block;
      margin-block-start: $spacing04;
    }
  }

  h4 {
    margin-block-start: $spacing08;
    margin-block-end: $spacing04 !important;
    color: $text-primary;
    @include h4();
  }

  .information-owner {
    margin-block-start: $spacing05;
    color: $blackwhite500;
  }

  .body-text {
    @include bodyLarge();
  }

  .tag-display {
    margin-block-start: $spacing08;
  }
}