@charset 'utf-8';
@import '../utilities/variable';
@import '../utilities/mixin';

.dynamic-toast-container {
  padding-inline: 0 !important;
  margin-block-start: 71px;
  .toast {
    min-width: 100% !important;
    border-radius: var(--spacing-01, 2px);
    border-left: 4px solid var(--primative-colors-yellow-yellow-500, #FFC107);
    background: var(--primative-colors-bw-pure-white, #FFF);
    box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.13), 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
    .toast-header {
      padding: $spacing06;
      color: $text-primary;
      @include h4();
      margin-inline-end: $spacing03;
      .toast-message {
        min-width: 100%;
        display: inline !important;
        svg {
          color: $pureblack;
          margin-inline-end: $spacing03;
          width: 16px;
          height: 16px;
          margin-block-end: $spacing03;
        }
        span {
          @include bodyLarge();
          max-width: 80%;
          p {
            padding-inline-start: $spacing03;
            display: inline;
            max-width: 80%;
            margin-block-end: 0;
          }
        }
      }
      button {
        border: none;
        background-color: $purewhite;
        .sr-only {
          display: none;
        }
      }
    }

    
  }
}