// TYPOGRAPHY MIXINS -------------------------------------------------------
@import '../../fonts/OpenSans-Italic-VariableFont_wdth\,wght.ttf';
@import '../../fonts/OpenSans-VariableFont_wdth\,wght.ttf';

@mixin displayLarge {
  font-family: $openSans;
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem; //36px
  line-height: 2.5rem; //40px
  letter-spacing: -0.05em;
}

@mixin displayMedium {
  font-family: $openSans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem; //28px
  line-height: 2rem; //32px
  letter-spacing: -0.04em;
}

@mixin displaySmall {
  font-family: $openSans;
  font-weight: 600;
  font-style: normal;
  font-size: 1.25rem !important; //20px
  line-height: 1.5rem !important; //24px
  letter-spacing: -0.03em;
}

@mixin h1 {
  font-family: $openSans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem !important; //24px
  line-height: 2.25rem !important; //36px
  letter-spacing: -0.01em;
}

@mixin h2 {
  font-family: $openSans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem !important; //20px
  line-height: 1.5rem !important; //24px
  letter-spacing: 0em;
}

@mixin h3 {
  font-family: $openSans;
  font-weight: 600 !important;
  font-style: normal;
  font-size: 1.25rem !important; //20px
  line-height: 1.5rem !important; //24px
  letter-spacing: 0em;
}

@mixin h4 {
  font-family: $openSans;
  font-weight: 600;
  font-style: normal;
  font-size: 1.125rem !important; //18px
  line-height: 1.375rem !important; //22px
  letter-spacing: -0.01em;
}

@mixin h5 {
  font-family: $openSans;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem !important; //16px
  line-height: 1.25rem; //20px
  letter-spacing: 0em;
}

@mixin h6 {
  font-family: $openSans;
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem !important; //14px
  line-height: 1rem; //16px
  letter-spacing: 0em;
}

@mixin bodyLarge {
  font-family: $openSans;
  font-weight: 400;
  font-style: normal;
  font-size: 1.125rem !important; //18px
  line-height: 1.375rem; //22px
}

@mixin bodyMedium {
  font-family: $openSans;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem !important; //16px
  line-height: 1.25rem; //20px
}

@mixin bodySmall {
  font-family: $openSans;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem !important; //14px
  line-height: 1rem; //16px
}

@mixin subtitle1 {
  font-family: $openSans;
  font-weight: 400;
  font-style: normal;
  font-size: 0.75rem !important; //12px
  line-height: 1rem; //16px
}

@mixin subtitle2 {
  font-family: $openSans;
  font-weight: 400;
  font-style: normal;
  font-size: 0.625rem !important; //10px
  line-height: 0.75rem; //12px
}

@mixin button {
  font-family: $openSans;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem !important; //14px
  line-height: 1rem; //16px
  letter-spacing: -0.02em;
}

@mixin labelLarge {
  font-family: $openSans;
  font-weight: 400;
  font-style: normal;
  font-size: 1.125rem !important; //18px
  line-height: 1.375rem; //22px
  letter-spacing: 0.02em;
}

@mixin labelMedium {
  font-family: $openSans;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem; //16px
  line-height: 1.25rem; //20px
  letter-spacing: 0.03em;
}

@mixin labelSmall {
  font-family: $openSans;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem; //14px
  line-height: 1rem; //16px
  letter-spacing: 0.05em;
}

@mixin buttonSmall {
  font-family: $openSans !important;
  font-size: 0.75rem !important; //12px;
  font-style: normal !important;
 // font-weight: 600 !important;
  line-height:  0.875rem !important; //14px
  letter-spacing: 0.05em !important;
}

@mixin buttonMedium {
  font-family: $openSans !important;
  font-style: normal !important;
  font-size: 0.875rem !important; // 14px
  line-height: 1rem !important; // 16px
  letter-spacing: -0.02em !important;
}


// NEW 6/28/24
@mixin buttonLarge {
  font-family: $openSans !important;
  //font-weight: 600 !important;
  font-style: normal !important;
  font-size: 0.875 !important; // 14px
  line-height: 1rem !important; // 16px
  letter-spacing: 0.02em !important;
}

@mixin buttonPrimaryLarge {
  border-radius: 2px;
  background: $surface-brand-primary;
  border: 2px solid $surface-brand-primary !important;
  color: $text-primary;
  @include buttonLarge();
  &:hover {
    background: $surface-brand-primary;
    border: 2px solid $surface-brand-primary !important;
    color: $text-primary;
  }
}

@mixin buttonGhost {
  @include buttonLarge();
  background-color: $purewhite !important;
  color: $blackwhite900 !important;
  border: none;
}



// Font Weights Isolated 
@mixin weightSemibold {
  font-weight: 600;
}

@mixin weightNormal {
  font-weight: 400;
}

@mixin weightLight {
  font-weight: 300;
}

@mixin pbiTall {
  width: 100%;
  height: 100vh;
  min-height: 400px;
  zoom: 1.2;

  @media (min-width: 2440px) {
    // Styles for big monitor
    height: 70vh;  
    zoom: 1.5;
  }

  @media (resolution: 144dpi) {   
    // Styles for 150% zoom 
    width: 100%;
    height: 130vh;  
    zoom: .85;
  }

  @media (resolution: 120dpi) {
    // Styles for 125% zoom
    zoom: 1;
  }
}

@mixin pbiStandard {
  width: 100%;
  height: 100vh;
  zoom: .89;

  @media (min-width: 2440px) {
    // Styles for big monitor
    height: 70vh;  
    zoom: 1.5;
  }

  @media (resolution: 144dpi) {  
    // Styles for 150% zoom  
    width: 100%;
    height: 120vh;  
    zoom: .69;
  }

}

@mixin pbiSmall {
  width: 100%;
    height: 35vh;
    min-height: 420px;
    zoom: .8;

  @media (min-width: 2440px) {
    zoom: 1.2;
  }

  @media (resolution: 144dpi) { 
    // Styles for 150% zoom   
    width: 100%;
        height: 35vh;  
        zoom: .6;
  }
}