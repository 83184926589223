@import '../utilities/variable.scss';
@import '../utilities/variable_classes';
@import '../utilities/mixin.scss';

// INDUSTRY ANALYSIS STYLES -----------------------------------------------------------------

.INDUSTRY_ANALYSIS {
  .company-field-display {
    margin-top: 0;

    .field-display-pbi {
      @include pbiTall();
    }
  }
  p {
    @include bodyMedium();
  }
  ul {
    @include bodyMedium();
  }
  .runtime-slid-tabs-container {
    font-family: $openSans !important;
  }
}

.full-analysis-app {
  .industry-display-container {
    h1 {
      @include h1();
      padding: 8px 0;
    }
    
    .field-header {
      @include h2();
      line-height: 30px;
      word-spacing: -4%;
    }

    h3 {
      @include h3();
    }
  }
}