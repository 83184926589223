.pbi-report {
    width: 100%;
    height: 100vh;
    zoom: .9;
}

@media (resolution: 144dpi) {    
    .pbi-report {        
        width: 100%;
        height: 130vh;  
        zoom: .9;
    }     
}