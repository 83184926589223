@import '../utilities/variable.scss';
@import '../utilities/variable_classes';
@import '../utilities/mixin.scss';

.pagination-row {
  .col {
    max-width: 328px;
    &:nth-child(2) {
      margin-block-start: 8px;
      margin-inline-start: 16px;
    }
    .report-num {
      color: $blue500;
    }
  }
  .pagination {
    .page-link {
      .sr-only {
        display: none;
      }
    }
  }
}