@import '../utilities/variable.scss';
@import '../utilities/variable_classes';
@import '../utilities/mixin.scss';

.logtable {
    font-size: .8rem;

    .logtable__cell {
        vertical-align: top;
        padding: 8px;
    }


}