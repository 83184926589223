ul.rc-pagination,
ol.rc-pagination {
 margin: auto;
 padding: 0;
 list-style: none;
 margin-top: 20px;
 margin-bottom: 20px;
}

.rc-pagination-item, 
.rc-pagination-prev, 
.rc-pagination-jump-prev,
.rc-pagination-jump-next,
.rc-pagination-next {
   display: inline-block;
   min-width: 30px;
   height: 30px;
   margin-right: 8px;
   text-align: center;
   vertical-align: middle !important;
   list-style: none;
   padding-top: 4px;
   @include bodySmall();
   background-color: white;
   line-height: 1.5;
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   outline: 0;
   cursor: pointer;
   user-select: none;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
   display: inline-block;
   min-width: 30px;
   height: 30px;
   color: rgba(0, 0, 0, 0.85);
   @include bodySmall();
   line-height:1.5;
   text-align: center;
   vertical-align: middle;
   list-style: none;
   border-radius: 4px;
   cursor: pointer;
   transition: all 0.3s;
}

.rc-pagination-jump-prev button,
.rc-pagination-jump-next button {
   background: transparent;
   border: 0;
   cursor: pointer;
   color: #666;
   min-height: 30px;
   @include bodySmall();
   width: 30px;
 }

 li.rc-pagination-next button.rc-pagination-item-link,
 li.rc-pagination-prev button.rc-pagination-item-link {
   background: transparent;
   border: 0;
   cursor: pointer;
   color: #666;
   @include bodySmall();
   min-height: 30px;
   width: 30px;
}

.rc-pagination-jump-prev button:after,
.rc-pagination-jump-next button:after {
   display: block;
   content: '•••';
 }

.rc-pagination-item-active {
   font-weight: bold;
   border-color: #d10909d0;
   font-size: 0.75rem;
   @include h6();
   padding-top: 5px;
}

.rc-pagination-prev button:after {
   content: '‹';
   display: block;
   padding-bottom: 8px;
 }

 .rc-pagination-next button:after {
   content: '›';
   display: block;
   padding-bottom: 12px;
 }

 .rc-pagination-item-link {
  
      &::after {
         padding-bottom: 12px;
      }

 }

