@charset 'utf-8';
@import '../utilities/variable';
@import '../utilities/mixin';

.settings {

  &.card {
    margin-block-end: 16px;
    writing-mode: horizontal-tb;
  }

  .card-title {
    padding: 20px 0 0 20px;
    margin-bottom: 20px;
    @include h5();
  }
  .container {
    padding: 0 20px;

    .space-below {
      margin-block-end: 10px;
      writing-mode: horizontal-tb;
    }

    .inner-section {
      h6 {
        font-weight: bold;
        padding-top: 20px;
        border-top: 1px solid $blackwhite100;
        margin-block-end: $spacing03;
        writing-mode: horizontal-tb;
      }

      .help-text {
        font-size: 0.8rem;
        font-style: italic;
      }

      .no-items {
        padding: 20px 0;
        text-align: center;
      }

      .tool-bar {
        margin-top: 20px;
        .input-group-text {
          border: none;
          background-color: $purewhite;
        }
        input {
          border: none;

        }

        .more-act-col {
          text-align: right;
        }

        
      }

      .table {
        margin-top: 8px;
        thead {
          tr {
            background-color: $blackwhite100;
          }
        }
        tbody { 
          tr {
            display: fl;
            &:nth-child(odd) {
              background-color: $purewhite;
            }
            &:nth-child(even) {
              background-color: $blackwhite50;
            }
            td {
              padding: 0 8px;
              vertical-align: middle;
              .btn {
                padding: 0 16px;
                margin: 8px 0;
              }
            }
          }
        }
      }

      .app-access-picker.show {
        .dropdown-menu.show {
          min-width: 250px;
          .row {
            padding: 4px 0;
            .col-2 {
              max-width: 30px;
              padding: 0, 8px, 0, 12px;
            }
            .col-10 {
              padding: 0;
            }
          }
        }
      }
    }
    .setting-btns {
      .col {
       text-align: right;
       padding-right: 32px;
       .btn {
        margin: 0 0 20px 8px;
       }
      }
    }
  
  }

}

.invite-modal {
  // font-family: "Open Sans", sans-serif;
  // font-weight: 400;
  // font-style: normal;
  // font-size: 1rem;

  // .modal-title.h4 {
  //   font-size: 1.15rem;
  //   font-weight: 600;
  // }

  .modal-footer.right-align {
    justify-content: flex-end !important;
  }

  // input {
  //   font-family: "Open Sans", sans-serif;
  //   font-weight: 400;
  //   font-style: normal;
  //   font-size: 1rem;
  //   line-height: 1.25rem;
  //   width: 100%;
  //   border: none;
  //   box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  //   border-radius: 5px;
  //   color: #5E6171;
  //   padding: 8px 0 8px 8px;
  // }

  // label {
  //   color: #050A24;
  //   font-weight: 500;
  //   margin-top: 20px;
  //   font-size: .8rem;
  //   margin-bottom: 0.25rem;
  // }

  .error-report {
    color: red;
    font-size: .8rem;
  }

  label.error {
    font-size: .8rem;
    color: red;
  }
}