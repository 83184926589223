@import "../utilities/mixin";
@import "../utilities/variable";

.company-field-display {
  margin-top: 30px;
}

.company-analyses-panel {
  .rc-collapse-content-inactive {
    display: none;
  }

  .rc-collapse-item {
    border: none;
    .arrow {
      text-align: center;
      &:before {
        position: relative;
        top: -3px;
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-right: 1px solid #27292f;
        border-top: 1px solid #27292f;
        transform: rotate(45deg);
        margin-right: 0.5em;
        margin-left: 1em;
      }
    }
    .rc-collapse-header {
      padding: 8px 0;
      @include bodyMedium();
      border-top: 1px solid #27292f;
      border-bottom: 1px solid #27292f;
      color: #5e6171;
      .rc-collapse-expand-icon {
        display: inline;
      }
    }

    .rc-collapse-header:hover {
      cursor: pointer;
    }
  }

  .rc-collapse-item-active {
    .rc-collapse-header {
      color: #27292f;
      font-weight: 500;
      border-left: 4px solid #27292f;
    }
    .arrow {
      text-align: center;
      &:before {
        position: relative;
        top: -3px;
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-right: 1px solid #27292f;
        border-top: 1px solid #27292f;
        transform: rotate(135deg);
        margin-right: 0.5em;
        margin-left: 1em;
      }
    }
  }

  .tab-pane {
      & > p {
          margin-top: 15px;
          margin-bottom: 40px;
      }
  }
}
