@import '../utilities/variable.scss';
@import '../utilities/variable_classes';
@import '../utilities/mixin.scss';

.PARTNER_PROFILES {

  .app-list-item {
    border: none;
    img {
      height: 60px;
      width: 120px;
    }
  }

  .mini-weird-corners {
    margin-bottom: 12px;
    border: 1px solid #34353E;
  }

  .rc-collapse {
    margin-top: 24px;
  }

  #text {
    @include bodyMedium;
    padding: 8px 0;
    color: #000000;
  }

  .label {
    color: #34353E;
    @include subtitle2;
  }

  .company-field-display {
    h3 {
      @include h4();
      padding: 8px 0;
    }
    article {
      @include bodyMedium;
      padding: 8px 0;
    }
  }
}