// listView is used on the landing page of News, Intelligence, and Company Profiles

.page-header {
    margin-bottom: $spacing08;
    padding-left: 0;
}


.link-container:hover {
    cursor: pointer;
}

.app-list-item { // single list item, including title, date, preview text
    margin-bottom: $spacing06;

    .company-logo {
        width: 120px;
        height: 60px;
        object-fit: contain;
        padding: $spacing01;
        margin-bottom: $spacing04;
    }

    .article-date {
        @include subtitle1;
        color: $blue600;
        padding-top: 0;
        padding-bottom: 8px;
        margin-bottom: 0; // override _reboot.scss
    }
    
    h3 {
        @include h3;
    }
    
    .source-date {
        @include subtitle1;
        padding-top: 0;
        padding-bottom: 0;
    }

    p {
        @include bodyLarge;
        padding-top: $spacing02;
        margin-bottom: 0;
        em {
            font-weight: 700;
            font-style: normal;
        }
    }

    ul, li {
        @include bodyLarge;
        padding-inline-start: 0;
        list-style: none;
        margin-bottom: 0;
    }

    .tag-display {
        padding-top: $spacing03;
        padding-bottom: $spacing05;

        a {
            padding-right: $spacing03;
           
            .tertiary-button {
                padding-inline: $spacing03;
                padding-block: $spacing02;
                writing-mode: horizontal-tb;
                border-radius: 2px;

                .icon {
                    margin-inline-end: $spacing03;
                    writing-mode: horizontal-tb;
                    width: 1.4em;
                    height: 1.4em;

                    &.close {
                        margin-inline-end: inherit;
                        margin-inline-start: $spacing03;
                        writing-mode: horizontal-tb;
                    }
                    
                }
                @include subtitle1;
            }
        }

        .tag-btn {
            &.tertiary-button {
                padding-inline: $spacing03;
                padding-block: $spacing02;
                writing-mode: horizontal-tb;
                border-radius: 2px;
    
                &.badge{
                    .icon {
                    margin-inline-end: $spacing03;
                    writing-mode: horizontal-tb;
                    width: 1.4em;
                    height: 1.4em;
    
                        &.close {
                            margin-inline-end: inherit;
                            margin-inline-start: $spacing03;
                            writing-mode: horizontal-tb;
                        }
                    }
                }
    
                
                    
            }
        }
        
    }

    .card-body {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
    }


}

