@charset 'utf-8';
@import '../utilities/variable';
@import '../utilities/mixin';

.confirmation-modal {

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13), 0px 0.5px 1px 0px rgba(0, 0, 0, 0.10);


    .modal-header {
      border-radius: 2px 2px 0px 0px;
      align-self: stretch;
      border: none;
    }

    .modal-body {
      @include bodyMedium();
      padding: 16px 20px 0 20px;
      width: 100%;

      .subtitle {
        @include subtitle1();
        padding-block-end: $spacing04 !important;
      }
    }

    .modal-footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        border: none;
    }
    
    }
  }

    // .custom-modal-position {
    //   width: 80% !important;
    //   max-width: none !important;
    //   transform: translate(0, 50%) !important;
  
    //   @media (max-width: 1400px) {
    //     .custom-modal-position {
    //       transform: translate(0, 10%) !important;
    //     }
  
  
        @media (max-width: 992px) {
  
          // override
          .icon-btn {
            margin-block-start: 0px !important;
          }
   }
    //   }
    // }