@charset 'utf-8';
@import '../utilities/variable';
@import '../utilities/mixin';

.dynamic-modal {

  .modal-dialog {
    margin-block-start: 228px;
  }
  .modal-content {
    display: flex;
    width: 636px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13), 0px 0.5px 1px 0px rgba(0, 0, 0, 0.10);


    .modal-header {
      border-radius: 2px 2px 0px 0px;
      background: radial-gradient(185.11% 90.18% at 106.37% 113.98%, #D72E3D 0%, #7226B9 100%);
      height: 200px;
      align-self: stretch;
      .close {
        display: none !important;
      }
    }
    .modal-body {
      @include bodyMedium();
      padding: 16px 20px 0 20px;
      h3 {
        @include h3();
        margin-block-end: 0;
      }

      .subheader{
        @include h4();
      }
      .subtitle {
        @include subtitle1();
        padding-block-end: $spacing04 !important;
      }
      p {
        margin-block-end: 0;
      }
    }
    .modal-footer {
      display: flex;
      align-self: center;
      padding-block-start: 0;
      border-block-start: none;
      button {
        display: flex;
        padding: 10px 20px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 2px;
        background: $blue500;
      }
    }
  }
}