// styling for support request modal, accessed through "Support Request" link in footer
// SupportModal.js
@charset 'utf-8';
@import '../utilities/variable';
@import '../utilities/mixin';




.support-modal {
    
    .modal-content {
        z-index: 2;

        .support-modal-header.modal-header {
           .support-modal-title { // "We'd love to hear your feedback!"
                @include h3;
                padding: 8px 0;
            }
        }

        .support-modal-title-submitted { // Thank you for your submission!
            padding-bottom: 8px;
        }

        .support-modal-text-submitted {
            padding-bottom: 8px;
        }

        button.thankyou-close {
            @include button;
            padding: 12px 40px;
            float: right;
        }
        .modal-body { // everything under header
            .support-modal-subtitle {
                @include bodyLarge;
                padding: 8px 0 40px 0;
            }
            
        
            .form {

                .form_textfield.form-group { // Your Email, and the email field
                    padding-bottom: 12px;

                    .form-label { // "Your Email"
                        @include subtitle1;
                    }

                    #email {} // the email address
                }
            }

            .form_dropdown { // dropdown group
                color: #27292F;

                .support-category { // "Category"
                    padding-top: 12px;
                }
            }

            .support-details-group { // Details group
                padding-top: 12px !important;
                padding-top: 12px !important;

                .support-details{ // "Feedback"
                    padding-top: 8px;
                }

            }

            

            .support-file-upload {
                padding-top: 12px;
            }


            .custom-file-button {
                padding-top: 12px;
                input[type="file"] {
                    margin-left: -2px !important;
            
                    &::-webkit-file-upload-button {
                        display: none;
                    }
                    &::file-selector-button {
                        display: none;
                    }
                }
            
                &:hover {
                    label {
                        background-color: #0D6EFD;;
                        cursor: pointer;
                    }
                }

                .input-group-text {
                    background-color: #0D6EFD;
                    color: #EFEFF1;
                    @include button;
                    padding: 12px 22px;
                }
            }

            .text-end {// button group
                padding-top: 40px;
                
                .btn-light { // Cancel
                    background-color: $purewhite;
                    border: none;
                    color: #5D9EFE;
                    padding: 12px 40px;
                }

                .btn-primary { // Submit 
                    @include button;
                    padding: 12px 40px;
                }
    }       }
    
}
}

