.LBGantt-container {

  height: 100%;

  .gantt-col {
    border-left: 1px solid $borders-border-secondary;
  }

  .LBGantt-tools {
    background-color: $surface-light-secondary;
    color:$text-primary;

    .toolbar-col {
      padding-left: 0;
      max-width: 160px;

      .btn-toolbar {
        padding: 8px 8px 0 8px;
        button {
          background-color: #8b8d97;
          border: 1px solid #8b8d97;
          text-transform: uppercase;
        }
      }

      .intervention-header {
        padding: 0 0 5px 20px;
        font-weight: bold;

        &.group-by-field {
          height: 100%;
          padding-top: 20px;
        }
      }
    }

    .LBGantt-col {
      .LBGantt-header-row {
        .LBGantt-header-top {
          @include bodySmall();
          line-height: 2rem;
          padding: 8px 16px 8px 14px;
        }
      }
    }
  }

  .LBGantt-row {
    border-top: 1px solid $borders-border-secondary;
    background: $surface-secondary; //override on specific columns

    .col-0 {
      max-width: 160px;
      border-left: none !important;
      
    }
    .col-1 {
      max-width: 55px;
    }

    .LBGantt-col {
      padding: 8px 16px;
      align-items: center;
      height: 100%;
      border-left: 1px solid $borders-border-secondary;
      
      a {
        text-decoration: none;
        cursor:pointer;
        color: inherit;
      }

      .col-link {
        svg {
          color: $tps-dark;
        }
      }
      span {
        @include bodySmall();
      }

      .LBGantt-value-bar {
        margin-top: 4px;
        border-radius: 5px;
        min-height: 16px;
        background-color: $gaviBlue100;
        color: $text-primary;
        border: 1px solid $borders-border-secondary;

        &.no-def-dates {
            background-color: transparent !important;
            border: 1px dashed $borders-border-secondary;
        }

        .badge {
          background-color: $indigo500;
          margin-top: 0.6em;
        }
        .my-auto {
          align-items: center;
          .col-4 {
            span {
              margin-block: $spacing03;
            }
          }
        }
      }
    }
  }
}

// Styles if no interventions exist
.no-interventions {
  background-color: $surface-light-secondary;
  h4 {
    @include h4();
    margin-block-end: $spacing06 !important;
  }
  .add-intervention-col {
    justify-content: center !important;
    .btn {
      max-width: 191px;
      @include buttonLarge();
    }
    .go-back-btn {
      background-color: $surface-light-secondary;
      border: 1px solid $surface-light-secondary;
      color: $text-secondary;
      @include buttonSmall();
    }
  }
}